<template>
  <div class="areawrap">
    <b-row class="justify-content-between mb-4">
      <b-col cols="4">
        <div class="position-relative">
          <b-form-input v-model="search" placeholder="Search" class="pr-12" />
          <font-awesome-icon :icon="['fas', 'search']" fixed-width class="input-search" />
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button variant="secondary" class="btn-action">
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
        </b-button>
      </b-col>
    </b-row>

    <div class="table-base">
      <b-table hover borderless :items="items" :fields="fields">
        <template #head(title)>
          TITLE
          <font-awesome-icon :icon="['fas', 'sort']" fixed-width class="cursor-pointer" />
        </template>
        <template #head(published)>
          DATE PUBLISHED
          <font-awesome-icon :icon="['fas', 'sort']" fixed-width class="cursor-pointer" />
        </template>
        <template #head(category)>
          CATEGORY
          <font-awesome-icon :icon="['fas', 'sort']" fixed-width class="cursor-pointer" />
        </template>
      </b-table>
    </div>

    <b-row class="justify-content-between mb-4">
      <b-col cols="4">
        <b-form-select v-model="selected" :options="options" class="mb-4" />
      </b-col>

      <b-col cols="4">
        <b-button v-b-modal.modal-1 variant="primary" class="btn-width">
          modal
        </b-button>

        <b-modal id="modal-1" title="BootstrapVue" centered>
          <p class="my-4">
            Hello from modal!
          </p>
        </b-modal>
      </b-col>

      <b-col cols="12">
        <b-form-radio-group class="mb-8">
          <b-form-radio value="first">
            Toggle this custom radio
          </b-form-radio>
          <b-form-radio value="second">
            Or toggle this other custom radio
          </b-form-radio>
          <b-form-radio :value="{ fourth: 4 }">
            This is the 4th radio
          </b-form-radio>
        </b-form-radio-group>
      </b-col>

      <b-col cols="12">
        <div class="tabs-base">
          <b-tabs content-class="mt-3">
            <b-tab title="First" active>
              <p>I'm the first tab</p>
            </b-tab>
            <b-tab title="Second">
              <p>I'm the second tab</p>
            </b-tab>
            <b-tab title="Disabled">
              <p>I'm a disabled tab!</p>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Directory',
  data () {
    return {
      search: '',

      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' }
      ],
      fields: [
        {
          key: 'title'
        },
        {
          key: 'published'
        },
        {
          key: 'category'
        }
      ],
      items: [
        { title: 'title1', published: '2020/01/01 11:10:20', category: 'Dickerson' },
        { title: 'title2', published: '2020/01/01 08:20:20', category: 'Dickerson' },
        { title: 'title3', published: '2020/01/01 06:30:20', category: 'Dickerson' }
      ]
    }
  }
}
</script>
